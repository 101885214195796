// We don’t want anything too wide, so we skip the `lg` size entirely, and set a
// narrower max-width for `md`.
$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px
);

$container-max-widths: (
  sm: 420px,
  md: 600px
);

$code-color: #067a1e;

@import "~bootstrap/scss/bootstrap.scss";

pre {
  overflow: visible;
  border: solid 1px $gray-300;
  float: left;

  code {
    display: block;
    background-color: $light;
    overflow: visible;
    word-break: keep-all;
    white-space: pre;
    word-wrap: normal;
    padding: 3px;

    font-size: 95%;
  }

  min-width: 100%;
}

.content-editable pre {
  float: none;
}

.markdown-output {
  min-width: 100%;
  float: left;
}

@import "./demo/dom-view.css";
@import "./demo/block-view.css";

@include media-breakpoint-up(md) {
  .center-md {
    margin-left: 50%;
    transform: translateX(-50%);
  }
}

.toc p {
  margin-bottom: 0;
}
.toc li {
  list-style-type: none;
}
.toc ul {
  padding-left: 20px;
}

.tex sub {
  text-transform: uppercase;
  font-size: 100%;
}

.non-breaking-quote {
  padding-left: 1em;
  margin-bottom: 10px;
  border-left: 6px solid #d6d6d6;
  white-space: pre;
}

.demo-image img {
  border: 1px solid gray;
  margin-bottom: 10px;
}
