.block-view {
  display: grid;
}
.block-view__content {
  margin-top: 10px;
}
.block-view__item {
}
.block-view__prefix:hover {
  border: 1px solid #6c757d;
}
.block-view__prefix {
  margin-right: 10px;
  margin-top: 10px;
  border: 1px solid #ced4da;
  padding-left: 2px;
  padding-right: 2px;
}
.block-view__then {
  color: #6c757d;
}
